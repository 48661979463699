import { render, staticRenderFns } from "./addMonitor.vue?vue&type=template&id=8c405a72&scoped=true&"
import script from "./addMonitor.vue?vue&type=script&lang=js&"
export * from "./addMonitor.vue?vue&type=script&lang=js&"
import style0 from "./addMonitor.vue?vue&type=style&index=0&id=8c405a72&prod&lang=less&"
import style1 from "./addMonitor.vue?vue&type=style&index=1&id=8c405a72&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c405a72",
  null
  
)

export default component.exports